import { Injectable, inject } from "@angular/core";
import { AuthService, IdToken } from "@auth0/auth0-angular";
import { ClaimData } from "../../types/claim-data";
import { map } from "rxjs";
import { isValidClaim } from "../../guards/isValidClaim";

@Injectable({
  providedIn: "root",
})
export class ClaimsService {
  private auth = inject(AuthService);

  private readonly claimPrefix = 'https://inlights.io/';

  public claims$ = this.auth.idTokenClaims$.pipe(
    map((idTokenClaims: IdToken | null | undefined) => {
      const claims: ClaimData[] = [];
      for (const key in idTokenClaims) {
        if (Object.prototype.hasOwnProperty.call(idTokenClaims, key)) {
          if (key.startsWith(this.claimPrefix)) {
            const claim: ClaimData = {
              name: key.replace(this.claimPrefix, ""),
              value: idTokenClaims[key],
            };
            if (isValidClaim(claim)) {
              claims.push(claim);
            }
          }
        }
      }
      return claims;
    }),
  );

  public isAuthenticated$ = this.auth.isAuthenticated$;
}
