import { ClaimData } from "../types/claim-data";

export function isValidClaim(claim: ClaimData): boolean {
  const isInvalidValue = (value: string) => {
    if (value === "") return true;
    if (value.trim() !== value) return true;
    return false;
  };
  const containsDuplicates = (values: string[]) => {
    const uniqueValueSet = new Set(values.map(x => x.toLowerCase()));
    return uniqueValueSet.size !== values.length;
  };
  if (claim.name === "") return false;
  if (claim.name.trim() !== claim.name) return false;
  if (claim.value.length === 0) return false;
  if (claim.value.some(isInvalidValue)) return false;
  if (claim.value.length > 1 && containsDuplicates(claim.value)) return false;
  return true;
}
